import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import { palette } from '@/charts/colors.js';

import HighchartsFunnel from 'highcharts/modules/funnel';
HighchartsFunnel(Highcharts);

import HighchartsMapModule from 'highcharts/modules/map';
HighchartsMapModule(Highcharts);

Highcharts.setOptions({
    accessibility: {
        enabled: false,
    },
    lang: {
        thousandsSep: '.',
        decimalPoint: ',',
        months: [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
        ],
        weekdays: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'],
        shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    },
    xAxis: {
        dateTimeLabelFormats: {
            // millisecond: '%H:%M:%S.%L',
            // second: '%H:%M:%S',
            // minute: '%H:%M',
            // hour: '%H:%M',
            // day: '%e. %b',
            // week: '%e. %b',
            month: '%b ',
            year: '%Y',
        },
    },

    chart: {
        plotBackgroundColor: 'transparent',
        backgroundColor: 'transparent',
        plotBorderWidth: 0,
        style: {
            fontFamily: 'Monserrat, sans-serif',
        },
    },
    credits: {
        enabled: false,
    },
    title: {
        text: '',
    },
    subtitle: {
        text: '',
    },
    legend: {
        enabled: false,
    },
    global: {
        timezoneOffset: new Date().getTimezoneOffset(),
    },
    tooltip: {
        enabled: true,
        shared: true,
        backgroundColor: '#161329',
        borderWidth: 0,
        valueDecimals: 2,
        //valueSuffix: '%',
        style: {
            color: '#fff',
        },
        dateTimeLabelFormats: {
            day: '%d/%m/%Y',
        },
        //zIndex:20
    },
    plotOptions: {
        areaspline: {
            fillOpacity: 0.1,
        },
    },
    //colors: chartGradients(),
    colors: palette,
});

export { HighchartsVue, Highcharts };
