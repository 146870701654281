function withOpacity(variableName) {
    return ({ opacityValue }) => {
        if (opacityValue !== undefined) {
            return `rgba(var(${variableName}), ${opacityValue})`;
        }
        return `rgb(var(${variableName}))`;
    };
}
import dark from './themes/dark.js';
import light from './themes/light.js';

//export const colorsComputed = computed(() => {
//console.log('state.themeMode :>> ', state.themeMode);
// if (state.themeMode == 'dark') {
//     return dark;
// }
// return light;
//});

let theme = {};
Object.entries(light).reduce((acc, [key, value]) => {
    acc[key] = withOpacity('--color-' + key);
    return acc;
}, theme);

export default theme;

export const gradients = {
    a: ['#D38DDE', '#814CD6'],
    b: ['#0ACFFE', '#495AFF'],
    c: ['#485563', '#2C3946'],
    d: ['#8CEABB', '#378F7B'],
    e: ['#FE694E', '#F7CB85'],
    f: ['#ED213A', '#FF5E62'],
    g: ['#625E4B', '#D3CBB8'],
    h: ['#EAE694', '#E69D54'],
    i: ['#A35E67', '#F0BDC4'],
};
