import { createRouter, createWebHistory, useRoute } from 'vue-router';
import routes from './routes.js';

import Auth from '@/api/endpoints/Auth.js';
import { state, actions } from '@/store';

import { useRouter } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: routes(authGuard, guestGuard, publicGuard),
});

export default router;

router.beforeEach(async (to, from) => {
    if (to.meta.dashboard) {
        actions.setDashboard(to.meta.dashboard);
    }

    if (state.token) {
        try {
            const users = await Auth.me();
            actions.setUser(users.data);

            // if (state.lastLoggedUser) {
            //     const user = users.find(u => u.id == state.lastLoggedUser);
            //     actions.setUser(user);
            // }
        } catch (error) {
            actions.resetAuth();
        }
    } else if (to.name !== 'Login') {
        const router_page = useRouter();
        await router_page.push({ name: 'LoginParent' });
    }

    let allowed = true;
    let permissions = to.meta?.permissions;

    if (permissions) {
        let userPermissions = state.user?.dashboard_users?.map(el => {
            let id = el.dashboard.link_id;
            let splitted = id.split(' | ');
            return splitted[1];
        });

        if (state.user) {
            allowed = userPermissions.includes(permissions);
        }
        if (!allowed) {
            return { name: 'Home' };
        }
    }

    if (to.query.hasOwnProperty('token')) {
        await actions.setToken(to.query.token);
    }

    // next();
});

function beforeEnter(routes, callback) {
    return routes.map(route => {
        return { ...route, beforeEnter: callback };
    });
}

export function authGuard(routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (!state.token) {
            actions.setForward(to);
            return next({ name: 'LoginParent' });
        }

        next();
    });
}

function guestGuard(routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (state.token) {
            return next({ name: 'Home' });
        }

        next();
    });
}

function publicGuard(routes) {
    return beforeEnter(routes, (to, from, next) => {
        next();
    });
}
