import Endpoint from '../Endpoint';

class Auth extends Endpoint {
    baseUrl() {
        return import.meta.env.VITE_APP_API_URL;
    }

    buildUrl({ action }) {
        return [action];
    }

    async login(form) {
        return this.request({
            action: 'login',
            method: 'POST',
            data: form,
        });
    }

    logout() {
        return this.request({
            action: 'logout',
            method: 'POST',
        });
    }

    me() {
        return this.request({
            action: 'me',
            method: 'GET',
        });
    }
}

export default new Auth();
