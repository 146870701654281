export default {
    /* BRAND COLORS */
    brand_red: '#F54963',
    brand_red_80: '#F54963CC',
    brand_red_60: '#F5496399',
    brand_red_40: '#F5496366',
    brand_red_20: '#F5496333',

    black: '#0A263B',
    brand_blue: '#0A263B',
    brand_blue_80: 'rgba(10,38,59,0.8)',
    brand_blue_60: 'rgba(10,38,59,0.6)',
    brand_blue_40: 'rgba(10,38,59,0.4)',
    brand_blue_20: 'rgba(10,38,59,0.2)',

    brand_green: '#76CC9B',
    brand_green_80: '#76CC9BCC',
    brand_green_60: '#76CC9B99',
    brand_green_40: '#76CC9B66',
    brand_green_20: '#76CC9B33',

    //brand_turquoise: {
    //    DEFAULT: '#36A7B7',
    //    xl: 'rgba(54,167,183,0.8)',
    //    md: 'rgba(54,167,183,0.6)',
    //    sm: 'rgba(54,167,183,0.4)',
    //    xs: 'rgba(54,167,183,0.2)',
    //},

    brand_turquoise: '#36A7B7',
    brand_turquoise_80: 'rgba(54,167,183,0.8)',
    brand_turquoise_60: 'rgba(54,167,183,0.6)',
    brand_turquoise_40: 'rgba(54,167,183,0.4)',
    brand_turquoise_20: 'rgba(54,167,183,0.2)',
    brand_turquoiseXXS: 'rgba(54,167,183,0.2)',

    brand_purple: '#8B8BB2',
    brand_purple_80: '#8B8BB2CC',
    brand_purple_60: '#8B8BB299',
    brand_purple_40: '#8B8BB266',
    brand_purple_20: '#8B8BB233',

    brand_orange: '#FC6B08',
    brand_orange_80: '#FC6B08CC',
    brand_orange_60: '#FC6B0899',
    brand_orange_40: '#FC6B0866',
    brand_orange_20: '#FC6B0833',

    brand_gray_1: '#6D7475',

    brand_gray_2: '#878E90',

    brand_gray_3: '#ACB4B6',

    brand_gray_4: '#DDDDDD',
    brand_gray_4_60: '#DDDDDD99',
    brand_gray_4_20: '#DDDDDD33',

    /* BRAND COLORS */

    transparent: 'transparent',
    // current: 'currentColor',
    white: '#ffffff',

    body: '#DDDDDD',

    module: '#ffffff',
    box: '#F0F0F8',

    aux: '#36A7B7',

    shadow: '#ACB4B6',
};
