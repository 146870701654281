export const flatten = args => {
    return args.reduce((acc, val) => acc.concat(val), []);
};

export const URLJoin = args => {
    return args
        .join('/')
        .replace(/\/+/g, '/')
        .replace(/\/$/, '')
        .replace(/^(.+):\//, '$1://')
        .replace(/^file:/, 'file:/')
        .replace(/\/(\?|&|#[^!])/g, '$1')
        .replace(/\?/g, '&')
        .replace('&', '?');
};

export const asyncMap = async (data, callback) => {
    return await Promise.all(data.map(item => callback(item)));
};

export const resolver = async (data, inputKey, outputKey, replacer) => {
    return await asyncMap(data, async item => {
        const data = await replacer(item[inputKey]);

        return {
            ...item,
            [outputKey]: data,
        };
    });
};
