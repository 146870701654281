export const getLabel = slug => {
    const labels = {
        accumulated_ebitda: 'Accumulated EBITDA',
        accumulated_net_revenue: 'Accumulated net revenue',
        accumulated_operational_income: 'Accumulated operational income',
        amount: 'Amount',
        average_telmi_users_per_month: 'Average Telmi users per month',
        average_weight: 'Average weight',
        average_weighting: 'Average weighting',
        budget: 'Budget',
        business_unit: 'Business unit',
        by_categories: 'by Categories',
        closed_provisionally_won: 'Closed + provisionally won',
        cme: 'CME',
        comm_mkt_and_esg: 'Comm, Mkt and ESG',
        commercial_margin: 'Commercial margin',
        corporate_affairs_generation: 'Corporate Affairs Generation',
        corporate_expenses: 'Corporate expenses',
        cumulative_commercial_margin: 'Cumulative Commercial Margin',
        cumulative_personnel_expenses: 'Cumulative Personnel Expenses',
        currency: 'Currency',
        current_month_sales: 'Current Month Sales',
        current_quarter_sales: 'Current Quarter Sales',
        current_year_sales: 'Current Year Sales',
        desk: 'Desk',
        desktop: 'Desktop',
        directors: 'Directors',
        diversity: 'Diversity',
        ebitda: 'EBITDA',
        email_marketing: 'Email marketing',
        employee_turnover_rate: 'Employee turnover rate',
        eur: 'EUR',
        everyone: 'Everyone',
        exclude_provisional: 'Exclude provisional',
        female: 'Female',
        finance: 'Finance',
        finances: 'Finances',
        global_data: 'Global data',
        gross_margin: 'Gross Margin',
        hc_vs_budget: 'HC vs. Budget',
        hires: 'Hires',
        hires_detail: 'Hires detail',
        in_budget: 'In budget',
        include_provisional: 'Include provisional',
        income: 'Income',
        income_forecast: 'Income forecast',
        income_kcls: 'Income KCLs',
        international_mobility: 'International mobility',
        kcls_by_nb: 'KCLs by NB',
        key_account_leader: 'Key account leader',
        key_client_leaders: 'Key Clients Leaders',
        key_client_leaders_ranking: 'Key Client Leaders Ranking',
        key_client_leaders_ranking_short: 'KC Leaders Ranking',
        key_client_list: 'Key Client List',
        key_clients: 'Key Clients',
        key_clients_ranking: 'Key Clients Ranking',
        key_clients_ranking_short: 'KC Ranking',
        kpis: 'KPIs',
        last_3_publications: 'Last 3 publications',
        last_month_sales: 'Last Month Sales',
        last_year: 'Last year',
        last_year_ebitda: "Last year's EBITDA",
        last_year_income: "Last year's income",
        latam_north: 'LATAM north',
        latam_south: 'LATAM south',
        latin_america: 'Latin america',
        lead: 'Lead',
        leader_ranking_inc: 'Leader ranking inc',
        leader_ranking_list: 'Leader ranking list',
        leader_ranking_nb: 'Leader ranking nb',
        leaders_ranking: 'Leaders ranking',
        leads: 'Leads',
        organic_leads: 'Organic Leads',
        leads_to_prospects: 'Leads to prospects',
        likes_by_post: 'Likes by post',
        list_of_key_clients: 'List of Key Clients',
        local_mobility: 'Local mobility',
        loss_and_non_estimated: 'Loss and non estimated',
        mails_sent: 'Mails sent',
        male: 'Male',
        management_team: 'Management team',
        marketing: 'Marketing',
        marketing_generation: 'Marketing generation',
        media_impact: 'Media impact',
        missing: 'Missing',
        mobility: 'Mobility',
        month_to_month: 'Month to month',
        monthly_development: 'Monthly Development',
        monthly_development_of_ebitda: 'Monthly development of EBITDA',
        monthly_development_of_net_revenue: 'Monthly development of net revenue',
        monthly_development_of_operational_income: 'Monthly development of operational income',
        monthly_evolution: 'Monthly Evolution',
        monthly_evolution_of_commercial_margin: 'Monthly Evolution of Commercial Margin',
        monthly_evolution_of_gross_margin: 'Monthly Evolution of Gross Margin',
        monthly_evolution_of_other_expenses: 'Monthly Evolution of Other Expenses',
        monthly_evolution_of_personnel_expenses: 'Monthly evolution of personnel expenses',
        nb_incorporated: 'NB incorporated',
        nb_ponderation: 'NB Ponderation',
        nb_temporary: 'NB Temporary',
        nb_won: 'NB Won',
        nb_won_offered: 'NB won + offered',
        net_revenue: 'Net revenue',
        new: 'New',
        new_business_by_opportunity_type: 'New Business by opportunity type',
        new_business_by_type_of_opportunities: 'New Business by type of opportunities',
        new_business_incorporated: 'New Business Incorporated',
        new_business_ponderation: 'New Business Ponderation',
        new_creation: 'New Creation',
        newsletters_sent: 'Newsletters Sent',
        no_filters: 'No Filters',
        no_selected_filters: 'No selected filters',
        none: 'None',
        north_america: 'North america',
        oe_last_year: 'OE Last Year',
        open_opportunities: 'Open opportunities',
        open_rate: 'Open rate',
        operation: 'Operation',
        operational_income: 'Operational income',
        opportunities_by_origin: 'Opportunities by Origin',
        other_expenses: 'Other expenses',
        over_last_period: 'Over last period',
        over_previous_month: 'Over previous month',
        paid_media: 'Paid media',
        percent_of_international_mobility: '% of International Mobility',
        personal_expenses: 'Personal expenses',
        personnel_costs_per_business: 'Personnel costs per business',
        pipeline: 'Pipeline',
        pipeline_by_origin: 'Pipeline by Origin',
        practice_area: 'Practice area',
        previous_month: 'Previous month',
        previous_period: 'Previous period',
        proactive_sales_funnel: 'Proactive Sales funnel',
        promotions: 'Promotions',
        prospect: 'Prospect',
        prospects_to_sales: 'Prospects to sales',
        rank_kcls_inc: 'Rank. kcls inc.',
        ranking_key_clients: 'Ranking key clients',
        recruiting_process: 'Recruiting process',
        recruiting_processes: 'Recruiting processes',
        region: 'Region',
        renewal: 'Renewal',
        revenue_volume: 'Revenue Volume',
        rotation: 'Rotation',
        sale: 'Sale',
        sales: 'Sales',
        sales_by_origin: 'Sales by Origin',
        sales_closed: 'Sales closed',
        sales_closed_won: 'Sales Closed Won',
        sales_current_vs_previous_year: 'Sales Current vs Previous Year',
        sales_development_vs_objectives: 'Sales Development vs Objectives',
        sales_funnel: 'Sales funnel',
        sales_generator: 'Sales generator',
        sales_generators: 'Sales generators',
        sales_vs_targets_evolution: 'Sales vs Targets Evolution',
        select: 'Select',
        select_business_unit: 'Select business unit',
        select_capability: 'Select capability',
        select_category: 'Select category',
        select_director: 'Select director',
        select_key_account_leader: 'Select key account leader',
        select_operation: 'Select operation',
        select_practice_area: 'Select practice area',
        select_practice_area_line: 'Select practice area line',
        select_practice_area_unit: 'Select practice area unit',
        select_rank: 'Select rank',
        select_region: 'Select region',
        select_subregion: 'Select subregion',
        select_type: 'Select type',
        service_type_analysis: 'Service type analysis',
        social_network: 'Social Network',
        staff_costs: 'Staff Costs',
        staff_expenses: 'Staff Expenses',
        staff_expenses_last: 'Staff Expenses Last',
        staff_costs_last: 'Staff Costs Last',
        starting_funds: 'Starting funds',
        starting_revenue: 'Starting revenue',
        substitution: 'Substitution',
        substitutions: 'Substitutions',
        talent: 'Talent',
        talent_detail: 'Talent detail',
        target: 'Target',
        telmi_users: 'Telmi users',
        temporary_sales_vs_objectives: 'Temporary Sales vs Objectives',
        temporary_sales_vs_target_evolution: 'Temporary Sales vs Targets Evolution',
        tier_1: 'Tier 1',
        time_to_hire: 'Time to hire',
        top_client_leaders_ranking: 'Top Client Leaders Ranking',
        top_client_leaders_ranking_short: 'TC Leaders Ranking',
        top_client_list: 'Top client list',
        top_clients: 'Top clients',
        top_clients_ranking: 'Top Clients Ranking',
        top_clients_ranking_short: 'TC ranking',
        top_leaders_ranking_short: 'TC Leaders Rank.',
        top_stories: 'Top stories',
        total_generation: 'Total Generation',
        total_incorporations: "Total Incorporation's",
        total_offered: 'Total Offered',
        total_processes: 'Total Processes',
        total_promotions: 'Total Promotions',
        total_sales: 'Total Sales',
        total_turnover: 'Total Turnover',
        traffic_sources: 'Traffic Sources',
        turndown_rate: 'Turndown Rate',
        turnover: 'Turnover',
        undesired: 'Undesired',
        usuarios_web_llyc: 'Usuarios Web LLYC',
        vs_internal: 'Vs. internal',
        vs_total_admissions: 'Vs. Total Admissions',
        web: 'Web',
        web_llyc_new_users: 'Web LLYC New Users',
        weighted: 'weighted',
        with_respect_to_the_previous_period: 'With respect to the previous period',
        won: 'Won',
        year_evolution: 'Year Evolution',
        year_to_year: 'Year to year',
    };

    slug.toString().trim();

    return labels[slug.toString().toLowerCase()] ?? slug.toString().replace('_', 'x');
};
