import Service from './Service';
import axios from 'axios';

import { useRouter } from 'vue-router';
//const router = useRouter();

import { state, actions } from '../store';
import Auth from '/src/api/endpoints/Auth.js';

import { notify } from '/src/components/notifications';

const config = { baseUrl: import.meta.env.VITE_APP_API_URL };
const http = axios.create(config);

class Endpoint extends Service {
    baseUrl() {
        return import.meta.env.VITE_APP_PRIVATE_API_URL;
    }

    makeRequest({ method, url, data, headers, query }) {
        return http({
            method,
            url,
            data,
            headers,
            params: query,
        });
    }

    beforeRequest(request) {
        // request.query = {
        //     ...request.query,
        //     token:state.token,
        // };

        request.headers = {
            ...request.headers,
            Authorization: `Bearer ${state.token}`,
        };
        return request;
    }

    afterRequest(response, request) {
        if (request.action === 'paginate') {
            return response.data;
        }

        return response.data;
    }

    async failedRequest(error) {
        const { status } = error.response;

        // Error 401: Unauthorized
        if (status === 401) {
            actions.resetAuth();
            //router.push({ path: '/' })
            window.location.reload();

            //await Auth.logout();
            notify({
                title: 'Error 401',
                text: 'n',
            });
        }

        // Error 403: Forbidden
        if (status === 403) {
            console.log('#Error 403: Forbidden');

            notify({
                title: 'Error 403',
                text: 'Forbidden',
            });
        }

        // Error 404: Not found
        if (status === 404) {
            console.log('#Error 404: Not found');

            notify({
                title: 'Error 404',
                text: 'Not found',
            });
        }

        // Error 419: Token mismatch
        if (status === 419) {
            console.log('#Error 419: Authentication Timeout');

            notify({
                title: 'Error 419',
                text: 'Authentication Timeout',
            });
        }

        // Error 422: Unprocessable entity
        if (status === 422) {
            console.log('#Error 422: Unprocessable entity');

            notify({
                title: 'Error 422',
                text: 'Unprocessable entity',
            });
        }

        // Error 500: Server error
        if (status >= 500) {
            console.log('#Error 500: Server error');

            notify({
                title: 'Error 500',
                text: 'Server error',
            });
        }

        if (error.response) {
            return Promise.reject(error);
        }
    }
}

export default Endpoint;
