<template>
    <div class="fixed right-0 bottom-0 px-3 py-3 pointer-events-none z-50">
        <TransitionGroup
            tag="div"
            class="pointer-events-auto"
            mode="out-in"
            move-class="transform transition-all duration-300"
            enter-active-class="transform transition-all duration-300 ease-out"
            leave-active-class="transform transition-all duration-300 ease-out absolute inset-x-0"
            enter-from-class="scale-75 opacity-0"
            enter-to-class="scale-100"
            leave-from-class="scale-100"
            leave-to-class="scale-75 opacity-0"
        >
            <div
                v-for="(notification, index) in notifications"
                :key="index"
                class="mt-2 first:mt-0 px-4 py-2 bg-white border rounded-xl shadow-md z-50 transform transition-all duration-300 ease-out"
                @click="remove(notification)"
            >
                <p class="text-gray-800 font-semibold">
                    {{ notification.title }}
                </p>

                <p class="text-sm text-gray-700">
                    {{ notification.text }}
                </p>
            </div>
        </TransitionGroup>
    </div>
</template>

<script>
    import { ref, onMounted, onUnmounted } from 'vue';
    import { uuid } from '/src/utils/uuid.js';
    import bus from '/src/utils/bus.js';

    export default {
        name: 'Notifications',
        setup() {
            const notifications = ref([]);

            function add(props) {
                const notification = {
                    id: uuid(),
                    duration: 3000,
                    ...props,
                };

                if (notification.duration >= 0) {
                    notification.timer = setTimeout(() => {
                        remove(notification);
                    }, notification.duration);
                }

                notifications.value.push(notification);
            }

            function remove(notification) {
                clearTimeout(notification.timer);
                const index = notifications.value.findIndex(item => item.id === notification.id);
                if (index >= 0) {
                    notifications.value.splice(index, 1);
                }
            }

            function handler(notification) {
                add(notification);
            }

            onMounted(() => {
                bus.on('notifications:add', handler);
            });

            onUnmounted(() => {
                bus.off('notifications:add', handler);
            });

            return {
                notifications,
                add,
                remove,
            };
        },
    };
</script>
