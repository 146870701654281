<template>
    <router-view />
    <!--
    <router-view class="hidden lg:flex" />
    <div class="flex flex-col lg:hidden items-center content-center justify-center flex-1 h-full">
        <ValueContainer size="lg">Please use Desktop view</ValueContainer>
        <ValueContainer size="md" :bold="false">Mobile views Coming Soon</ValueContainer>
    </div>-->
    <Interactions />
    <Notifications />
</template>

<script>
    // import Layout from '/src/components/layout';
    import { onBeforeUnmount, onMounted } from 'vue';
    import { Interactions } from '@/components/interactions';
    import { Notifications } from '@/components/notifications';

    import { state, actions } from '@/store';
    import ValueContainer from '@/components/common/ValueContainer.vue';
    import { isDesktop, isMobile } from '@/composables/utils.js';

    export default {
        name: 'App',
        computed: {
            isDesktop() {
                return isDesktop;
            },
        },
        methods: { isMobile },
        components: {
            ValueContainer,
            // Layout,
            Interactions,
            Notifications,
        },
        setup() {
            const checkWhatDevice = () => {
                if (isMobile()) {
                    actions.setDevice('mobile');
                } else {
                    actions.setDevice('desktop');
                }
            };

            onMounted(() => {
                checkWhatDevice();
                addEventListener('resize', () => {
                    checkWhatDevice();
                });
            });
            onBeforeUnmount(() => {
                removeEventListener('resize', () => {
                    checkWhatDevice();
                });
            });

            return {
                state,
            };
        },
    };
</script>
