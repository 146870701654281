let temp = [];
// export const palette = ['#A279E2', '#F0961A', '#f15581', '#3AC0FC', '#228B22']

export const palette = [
    '#E69D54',
    '#4F65E0',
    '#A279E2',
    '#DEAA8F',
    '#F87B7B',
    '#77AAD6',
    '#E69D54',
    '#F87B7B',
    '#B2CE7C',
    '#569A38',
];
import { gradients } from '/tailwindcss/colors';

export function chartGradients() {
    const ret = Object.keys(gradients).reduce(function (result, key) {
        let arr = gradients[key];
        result.push({
            linearGradient: {
                x1: 0,
                x2: 0,
                y1: 0,
                y2: 1,
            },
            stops: [
                [0, arr[0]],
                [1, arr[1]],
            ],
            color: arr[0],
            shadow: {
                color: arr[1],
                width: 6,
                offsetY: 3,
                opacity: 0.15,
            },
        });
        return result;
    }, []);
    return ret;
}

export const red = {
    linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
    },
    stops: [
        [0, '#DE5E5E'],
        [1, '#A83737'],
    ],
    color: '#DE5E5E',
};

temp.push(red);

export const white = {
    linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
    },
    stops: [
        [0, '#D5DDAE'],
        [1, '#969F6D'],
    ],
    color: '#D5DDAE',
};

temp.push(white);

export const purple = {
    linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
    },
    stops: [
        [0, '#D169BB'],
        [1, '#9C20A7'],
    ],
    color: '#D169BB',
};

temp.push(purple);

export const blue = {
    linearGradient: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 1,
    },
    stops: [
        [0, '#09CFFD'],
        [1, '#057098'],
    ],
    color: '#09CFFD',
};
temp.push(blue);

export const colors = temp;
