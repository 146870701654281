<template>
    <div
        class="px-3 py-3 fixed inset-0 flex pointer-events-none"
        :class="{ 'pointer-events-auto': interactions.length > 0 }"
    >
        <Transition
            mode="out-in"
            move-class="transform transition-all duration-300"
            enter-active-class="transform transition-all duration-300 ease-out"
            leave-active-class="transform transition-all duration-300 ease-out"
            enter-from-class="opacity-0"
            enter-to-class=""
            leave-from-class=""
            leave-to-class="opacity-0"
        >
            <div v-if="interactions.length > 0" class="absolute inset-0 bg-gray-900 bg-opacity-50"></div>
        </Transition>

        <TransitionGroup
            mode="out-in"
            move-class="transform transition-all duration-300 z-0"
            enter-active-class="transform transition-all duration-300 ease-out"
            leave-active-class="transform transition-all duration-300 ease-out"
            enter-from-class="scale-75 opacity-0"
            enter-to-class="scale-100"
            leave-from-class="scale-100"
            leave-to-class="scale-150 opacity-0"
            tag="div"
            class="relative m-auto pointer-events-auto"
        >
            <div
                v-for="(interaction, index) in interactions.slice(0, 5)"
                :key="interaction.id"
                class="absolute first:relative top-0"
                :style="{
                    'margin-top': `${index * -32}px`,
                    'z-index': `${interactions.length - index}`,
                }"
            >
                <div
                    class="px-4 py-6 w-64 rounded-2xl shadow-xl transform transition-all duration-100 ease-out bg-white"
                    :style="{ transform: `scale(${index * -0.1 + 1})` }"
                >
                    <div>
                        <p class="text-xl text-gray-800 font-semibold text-center">
                            {{ interaction.title }}
                        </p>

                        <p class="mt-1 text-sm text-gray-500 text-center">
                            {{ interaction.text }}
                        </p>

                        <div class="mt-6">
                            <button
                                @click="confirm(interaction)"
                                class="px-3 py-2 block w-full text-white bg-red-500 rounded-xl"
                                :disabled="interaction.loading"
                            >
                                {{ interaction.confirmLabel || 'Confirmar' }}
                            </button>

                            <button
                                @click="remove(interaction)"
                                class="mt-2 px-3 py-2 block w-full text-gray-700 rounded-xl"
                            >
                                {{ interaction.cancelLabel || 'Cancelar' }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </TransitionGroup>
    </div>
</template>

<script>
    import { ref, onMounted, onUnmounted } from 'vue';
    import { uuid } from '/src/utils/uuid.js';
    import bus from '/src/utils/bus.js';

    export default {
        name: 'Interactions',
        props: {
            limit: {
                type: Number,
                default: 5,
            },
        },
        setup(props) {
            const interactions = ref([]);

            function add(props) {
                const interaction = {
                    id: uuid(),
                    ...props,
                };
                interactions.value.unshift(interaction);
            }

            async function confirm(interaction) {
                if (interaction.confirm instanceof Function) {
                    try {
                        interaction.loading = true;
                        await interaction.confirm({
                            close: () => remove(interaction),
                        });
                        remove(interaction);
                    } catch (error) {
                    } finally {
                        interaction.loading = false;
                    }
                }
            }
            function remove(interaction) {
                const index = interactions.value.findIndex(item => item.id === interaction.id);
                if (index >= 0) {
                    interactions.value.splice(index, 1);
                }
            }

            function handler(interaction) {
                add(interaction);
            }

            onMounted(() => {
                bus.on('interactions:alert', handler);
            });

            onUnmounted(() => {
                bus.off('interactions:alert', handler);
            });

            return {
                interactions,
                add,
                confirm,
                remove,
            };
        },
    };
</script>
